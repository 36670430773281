import React, { useEffect, useRef, useState } from 'react';
import { prepareAssetsFolderUrl } from '../../../shared/getProductImageUrl';
import SectionHeading from '../../SectionHeading';
import DownloadBanner from '../../../assets/images/download-banner.png';
import DownloadBannerPlaceholder from '../../../assets/images/download-banner-placeholder.png';
import Img from '../../../components/Img';
const Download = () => {
  return <div className="download-container">
    <SectionHeading preText='Your home in your hands, Download ' boldText='Pintar' posText=' App' showPintarIcon />
    <div style={{lineHeight: 0}}>
      <Img srcLQ={DownloadBannerPlaceholder} srcHQ={DownloadBanner} className="download-pintar-app-banner" />
      {/* <img src={DownloadBanner} style={{width: '100%', height: 'auto'}} className='download-pintar-app-banner' /> */}
    </div>
  </div>
};

export default Download;